.card {
  position: relative;
  border-radius: 20px;
  padding: 4px 0px;
  margin: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  

  .card-image {
    -webkit-user-select: none;
    -webkit-touch-callout: none; 
    border: 4px solid #5d6270;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px -6px 10px rgb(60, 74, 116), 0px 4px 15px rgba(0, 0, 0, 0.15);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%
     
    }
  }

  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
    font-size: 16px;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .name {
    margin-bottom: 4px;
    display: none;
  }

  &.friends,
  &.owned {
    flex-direction: column;
    transform: scale(0.95);

    .vote {
      display: unset;
    }
    
    .info {
      z-index: 2;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      left: 4px;
      right: 4px;
      align-items: center;
      display: flex;
      flex-direction: column;
      background-color: rgba(68, 78, 109, 0.9);
      padding: 8px;
      bottom: 6px;
      position: absolute;
    }
    .name {
      display: block;
      font-size: large;
      font-weight: 500;
      padding-bottom: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.owned {
    .info {
      background-color: rgba(223, 198, 59, 0.7);
    }
    // background-color: #dfc63b;
  }
}

.card-wrapper {
  position: relative;
  .overlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
    border-radius: 20px;
    opacity: 0;
    display: flex;
    justify-content: center;
    padding: 16px;
    border: 1px solid transparent;
    transition: opacity,
      border-color 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .overlay.allow {
    z-index: 10;
    opacity: 0.9;
    background-color: #fff;
    border-color: #0B332D;
  }
  .overlay.disallow {
    z-index: 10;
    opacity: 0.9;
    background-color: #fff;
    border-color: #971500;
  }
}

@media only screen and (max-width: 600px) {
  .card-wrapper {
    padding: 0px;
  }
}

.MuiAvatar-colorDefault {
  background-color: #364269;
}
.vote {
  position: absolute;
  font-size: 56px;
  background-color: #263668;
  opacity: 0.97;
  border-radius: 20px;
  top: 42px;
  right: 0;
  left: 0;
  bottom: 4px;
  padding-top: 28px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-shadow: -3px 2px 5px #222;
  cursor: pointer;
  border: 4px solid #5d6270;
  &.selected {
    border-color:  #fff;
  }
  &.end {
    background-color: transparent;
  }

}



.center .vote {
  display: none;
}

.timer {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  .digit {
    display: inline-block;
    min-width: 28px;
  }
  .seperator {
    font-size: 16px;
    padding-right: 8px;
  }
}