

body {
  font-family: 'Inria Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
}
.App {
  padding: 16px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}


.timer {
  height: 32px;
}